import { Colors } from '../../../colors'

export default {
  styleOverrides: {
    root: {
      color: Colors.N80,
      fontWeight: 'normal',
      lineHeight: 1,
      '&.Mui-disabled': {
        color: Colors.N80
      }
    },
    outlined: {
      transform: 'translate(14px, 14px) scale(1)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(0px, -18px) scale(0.75)'
      }
    },
    marginDense: {
      whiteSpace: 'break-spaces'
    },
    shrink: {
      whiteSpace: 'nowrap'
    }
  }
}
