import { Colors } from '../../../colors'

export default {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: theme.spacing(),
      padding: `${theme.spacing()} ${theme.spacing(3)}`,
      border: `1px solid ${Colors.N20}`,
      lineHeight: theme.spacing(4)
    }),
    action: {
      marginRight: '0px'
    },
    icon: {
      display: 'none'
    },
    filledError: {
      backgroundColor: Colors.F05,
      borderColor: Colors.F50,
      svg: {
        color: Colors.F50
      }
    },
    filledWarning: {
      backgroundColor: Colors.PY05,
      borderColor: Colors.PY50,
      svg: {
        color: Colors.PY50
      }
    },
    filledSuccess: {
      backgroundColor: Colors.V05,
      borderColor: Colors.V50,
      svg: {
        color: Colors.V50
      }
    },
    filledInfo: {
      backgroundColor: Colors.C05,
      borderColor: Colors.C50,
      svg: {
        color: Colors.C50
      }
    }
  }
}
