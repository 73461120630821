export default {
  styleOverrides: {
    root: {
      border: 'none',
      boxShadow: 'none',
      padding: 0,
      borderWidth: 0,
      height: 6,
      width: 6,
      marginTop: 6,
      marginBottom: 6
    }
  }
}
