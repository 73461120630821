import { Colors } from '../../../colors'

export default {
  styleOverrides: {
    root: {
      width: 6,
      borderRadius: 6,
      backgroundColor: Colors.N30
    }
  }
}
