import { Colors } from '../../../colors'
const MARGIN = '6px'

export default {
  styleOverrides: {
    tooltip: {
      padding: '4px 6px',
      backgroundColor: Colors.N90
    },
    tooltipPlacementLeft: {
      margin: `0 ${MARGIN} !important`
    },
    tooltipPlacementRight: {
      margin: `0 ${MARGIN} !important`
    },
    tooltipPlacementBottom: {
      margin: `${MARGIN} 0 !important`
    },
    tooltipPlacementTop: {
      margin: `${MARGIN} 0 !important`
    }
  }
}
