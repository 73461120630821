import { Colors } from '../../../colors'

export default {
  styleOverrides: {
    gutterBottom: {
      marginBottom: 8
    },
    colorTextSecondary: {
      color: Colors.MID_GREY
    }
  }
}
