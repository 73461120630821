import { Colors } from '../../../colors'

export default {
  styleOverrides: {
    root: {
      '&.MuiInputBase-multiline': {
        padding: '10.5px 14px'
      },
      '&.Mui-disabled': {
        backgroundColor: Colors.N40
      },
      borderRadius: '6px'
    },
    notchedOutline: {
      borderColor: Colors.N80,
      borderWidth: '1px !important',
      '& legend': {
        width: '0px !important'
      }
    },
    input: {
      padding: '10.5px 14px',
      '&.Mui-disabled': {
        WebkitTextFillColor: Colors.N80
      },
      '&.MuiInputBase-inputMultiline': {
        padding: 0
      },
      '&::placeholder': {
        color: Colors.MID_GREY,
        fontSize: '14px'
      }
    },
    adornedEnd: {
      paddingRight: '12px'
    }
  }
}
