import palette from '../palette'
import { Colors } from '../../../colors'

export default {
  styleOverrides: {
    input: {
      fontSize: 14,
      padding: '4.5px 4px 4.5px 6px!important'
    },
    inputRoot: {
      '&[class*="Mui-disabled"]': {
        backgroundColor: palette.divider
      },
      '&[class*="MuiOutlinedInput-root"]': {
        padding: 6
      }
    },
    popupIndicator: ({ theme }) => ({
      right: theme.spacing(2)
    }),
    clearIndicator: {
      padding: '4px !important',
      display: 'none'
    },
    listbox: {
      '::-webkit-scrollbar': {
        display: 'block',
        WebkitAppearance: 'none',
        width: 6
      },
      '::-webkit-scrollbar-thumb': {
        borderRadius: 4,
        backgroundColor: Colors.N50
      },
      '::-webkit-scrollbar-track-piece': {
        borderRadius: 4,
        backgroundColor: Colors.N30
      }
    },
    option: {
      padding: '10px 16px !important'
    }
  }
}
