export default {
  styleOverrides: {
    root: {
      marginBottom: 0,
      border: 0,
      '&.MuiFormLabel-focused': {
        color: 'none'
      }
    }
  }
}
