import { Colors } from '../../../colors'

const THUMB_SIZE = 15
const TRACK_SIZE = 6

export default {
  styleOverrides: {
    root: {
      height: `${TRACK_SIZE}px`
    },
    rail: {
      color: Colors.N50,
      height: `${TRACK_SIZE}px`,
      borderRadius: `${TRACK_SIZE}px`
    },
    mark: {
      display: 'none'
    },
    markLabel: {
      fontSize: 11
    },
    markLabelActive: {
      color: Colors.N70
    },
    track: {
      height: `${TRACK_SIZE}px`,
      borderRadius: `${TRACK_SIZE}px`
    },
    thumb: {
      width: `${THUMB_SIZE}px`,
      height: `${THUMB_SIZE}px`
    }
  }
}
