export default {
  styleOverrides: {
    paper: {
      '&.multi-select .MuiMenuItem-root.Mui-selected': {
        backgroundColor: 'transparent'
      },
      '.MuiMenuItem-root.Mui-selected, .MuiMenuItem-root.Mui-selected:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)'
      }
    },
    root: {
      '.MuiBackdrop-root': {
        backgroundColor: 'rgba(0, 0, 0, 0)'
      }
    }
  }
}
