export default {
  styleOverrides: {
    select: {
      minHeight: '1.1876em',
      '&:focus': {
        backgroundColor: 'none'
      }
    },
    outlined: {
      borderRadius: '6px',
      padding: '10px 12px',
      fontSize: '14px'
    }
  }
}
