export default {
  styleOverrides: {
    contained: {
      boxShadow: 'none',
      backgroundColor: '#FFFFFF'
    },
    root: {
      borderRadius: '6px',
      fontSize: '14px',
      textTransform: 'none'
    },
    containedSizeLarge: {
      fontSize: '14px'
    }
  }
}
