import palette from '../palette'

export default {
  styleOverrides: {
    root: {
      color: palette.icon,
      '&.MuiToggleButton-selected': {
        color: palette.primary.main
      },
      '&:first-child': {
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4
      },
      '&:last-child': {
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4
      }
    }
  }
}
