import { Colors } from '../../colors'

export default {
  black: Colors.N100,
  white: Colors.N0,
  primary: {
    contrastText: Colors.N0,
    dark: Colors.C80,
    main: Colors.C50,
    light: Colors.C20
  },
  secondary: {
    contrastText: Colors.N0,
    dark: Colors.C70,
    main: Colors.C40,
    light: Colors.C40
  },
  error: {
    contrastText: Colors.N0,
    dark: Colors.F60,
    main: Colors.F50,
    light: Colors.F50
  },
  success: {
    contrastText: Colors.N0,
    dark: Colors.V60,
    main: Colors.V50,
    light: Colors.V50
  },
  info: {
    contrastText: Colors.N0,
    dark: Colors.C60,
    main: Colors.C50,
    light: Colors.C50
  },
  text: {
    primary: Colors.CG90,
    secondary: Colors.CG60,
    link: Colors.C40
  },
  icon: Colors.CG60,
  background: {
    default: Colors.N10,
    paper: Colors.N0
  },
  divider: Colors.N30
}
