export default {
  styleOverrides: {
    paper: ({ ownerState, theme }) => ({
      padding: ownerState.padding || 60,
      ...(ownerState.border === 'colored' && { border: `1px solid ${theme.palette.primary.dark}` })
    }),
    root: {
      '&.date-picker': {
        '.MuiPaper-root': {
          padding: 24
        }
      }
    }
  }
}
