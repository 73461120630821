import palette from '../palette'

export default {
  styleOverrides: {
    switchBase: {
      '&.Mui-checked': {
        color: palette.success.main
      },
      '&.Mui-checked+.MuiSwitch-track': {
        backgroundColor: palette.success.main
      }
    }
  }
}
