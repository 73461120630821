import palette from '../palette'

export default {
  styleOverrides: {
    root: {
      '&.MuiTableRow-selected': {
        backgroundColor: palette.background.main
      },
      '&.MuiTableRow-hover': {
        '&:hover': {
          backgroundColor: palette.background.main
        }
      }
    }
  }
}
