export default {
  styleOverrides: {
    root: {
      marginTop: '8px',
      width: '100%',
      '.MuiFormHelperText-root': {
        margin: 0
      }
    },
    marginDense: {
      marginBottom: '12px'
    }
  }
}
