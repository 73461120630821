import palette from '../palette'

export default {
  styleOverrides: {
    root: {
      minWidth: 120,
      lineHeight: '1.1876em',
      '&:hover:after': {
        borderBottomColor: palette.primary.main
      }
    },
    input: {
      boxSizing: 'initial !important', // override bootstrap from legacy styles
      height: 'auto',
      '&::placeholder': {
        opacity: 1,
        color: palette.text.secondary
      },
      '&:focus': {
        boxShadow: 'none'
      }
    }
  }
}
