export default {
  styleOverrides: {
    root: {
      paddingLeft: 12,
      paddingRight: 12,
      fontSize: 11,
      lineHeight: '13px',
      '& > svg': {
        marginRight: 12
      }
    },
    label: {
      display: 'flex',
      paddingRight: 14
    },
    labelSmall: {
      paddingLeft: 0,
      paddingRight: 0
    },
    deleteIconSmall: {
      marginLeft: 11
    },
    avatarSmall: {
      marginLeft: '0 !important',
      marginRight: '12px !important'
    }
  }
}
